import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Paths } from "../../MainRouter";

import "./Footer.styles.scss";

type IFooterProps = WithTranslation;

class Footer extends React.PureComponent<IFooterProps> {
	public render() {
		const { t } = this.props;

		return (
			<div className="footer-outer-wrapper">
				<div className="footer-wrapper">
					<div className="footer-content">
						{/* First Column */}
						<div className="footer-column">
							<img alt="eurycele" src="/assets/images/ec_white.svg" />
							<p>{t("common:footers.shortDescription")}</p>
						</div>

						{/* Second Column */}
						<div className="footer-column">
							<h3>{t("common:footers.navigate")}</h3>
							<ul>
								<li>
									<Link to={Paths.aboutUs}> {t("common:menus.aboutUs")}</Link>
								</li>
								<li>
									<Link to={Paths.ourWork}>{t("common:menus.services")}</Link>
								</li>
							</ul>
						</div>

						{/* Third Column */}
						<div className="footer-column">
							<h3>{t("common:contactUs")}</h3>
							<p>{t("common:footers.addressLine1")}</p>
							<p>{t("common:footers.addressLine2")}</p>
						</div>

						{/* Fourth Column */}
						<div className="footer-column">
							<Link className="btn-get-in-touch" to={Paths.contactUs} type="button">
								{t("common:menus.getInTouch")}
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const translation = withTranslation(["common"]);

export default translation(Footer);
